@font-face {
  font-family: coinbaseSans;
  src: url(./fonts/1277c31809ed67a9-s.p.woff2) format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: coinbaseSans;
  src: url(./fonts/b8fabe8e04a7e664-s.p.woff2) format('woff2');
  font-display: swap;
  font-weight: 500 800;
  font-style: normal;
}
@font-face {
  font-family: coinbaseSans_Fallback;
  src: local('Arial');
  ascent-override: 93.01%;
  descent-override: 23.25%;
  line-gap-override: 18.6%;
  size-adjust: 107.52%;
}
.__className_82adab {
  font-family: coinbaseSans, coinbaseSans_Fallback;
}
.__variable_82adab {
  --font-coinbase-sans: 'coinbaseSans', 'coinbaseSans_Fallback';
}
@font-face {
  font-family: coinbaseMono;
  src: url(./fonts/a6d16aa3f5f47660-s.p.woff2) format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: coinbaseMono;
  src: url(./fonts/231477a4b807a44f-s.p.woff2) format('woff2');
  font-display: swap;
  font-weight: 500 800;
  font-style: normal;
}
@font-face {
  font-family: coinbaseMono_Fallback;
  src: local('Arial');
  ascent-override: 71.3%;
  descent-override: 17.82%;
  line-gap-override: 14.26%;
  size-adjust: 140.26%;
}
.__className_149642 {
  font-family: coinbaseMono, coinbaseMono_Fallback;
}
.coinbaseMono {
  --font-coinbase-mono: 'coinbaseMono', 'coinbaseMono_Fallback';
}
@font-face {
  font-family: coinbaseDisplay;
  src: url(./fonts/1277c31809ed67a9-s.p.woff2) format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: coinbaseDisplay;
  src: url(./fonts/f5d629f910d1bad7-s.p.woff2) format('woff2');
  font-display: swap;
  font-weight: 500 800;
  font-style: normal;
}
@font-face {
  font-family: coinbaseDisplay_Fallback;
  src: local('Arial');
  ascent-override: 99.6%;
  descent-override: 24.9%;
  line-gap-override: 19.92%;
  size-adjust: 100.4%;
}
.__className_0f8946 {
  font-family: coinbaseDisplay, coinbaseDisplay_Fallback;
}
.__variable_0f8946 {
  --font-coinbase-display: 'coinbaseDisplay', 'coinbaseDisplay_Fallback';
}
